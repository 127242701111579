import React, { useState } from "react";
import { commands } from "../util/data";
import { motion } from "framer-motion";
import { useModal } from "./shared";

export const CommandsList = () => {
  const { openModal, isOpen, Modal } = useModal();
  const [currentCommand, setCurrentCommand] = useState(null);
  const [allCommands, setAllCommands] = useState(commands);

  const mappedCommands = allCommands.map((command, index) => (
    <Command
      setCurrentCommand={setCurrentCommand}
      clickFn={e => {
        setCurrentCommand(command);
        setAllCommands(
          allCommands.map(item => {
            if (item.number === command.number) {
              return {
                ...item,
                selected: true
              };
            }
            return {
              ...item,
              selected: false
            };
          })
        );
        openModal(e);
      }}
      key={index}
      command={command}
      type="fortynine-commands"
    />
  ));

  return (
    <>
      <div className="section">
        <div className="section__header">49 COMMANDS</div>
        <div className="section__list">{mappedCommands}</div>
      </div>
      {isOpen && (
        <Modal>
          <div className="i-am-modal__container commands">
            <div className="i-am-modal__passage">{currentCommand.passage}</div>
            <div className="i-am-modal__passage-reference">
              {currentCommand.passageReference}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export const Command = ({ command, animation, clickFn, selected, type }) => {
  return (
    <motion.div
      variants={animation}
      initial="hidden"
      animate="visible"
      className={`i-am-commands ${
        selected || (command && command.selected) ? "selected" : ""
      } ${
        type === "fortynine-commands"
          ? "fortynine-commands"
          : "seven-statements"
      }`}
      onClick={clickFn}
    >
      <div className="i-am-commands__title">{command.title}</div>
      <div className="i-am-commands__number">{command.number}</div>
    </motion.div>
  );
};

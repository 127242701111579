import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

import { reducer as responsive } from "redux-mediaquery";

const todosReducer = createReducer([], {
  ADD_TODO: (state, action) => {
    // "mutate" the array by calling push()
    state.push(action.payload);
  },
  TOGGLE_TODO: (state, action) => {
    const todo = state[action.payload.index];
    // "mutate" the object by overwriting a field
    todo.completed = !todo.completed;
  },
  REMOVE_TODO: (state, action) => {
    // Can still return an immutably-updated value if we want to
    return state.filter((todo, i) => i !== action.payload.index);
  }
});

const viewsReducer = createReducer("HOME", {
  SELECT_VIEW: (state, action) => {
    return action.payload;
  }
});

const rootReducer = combineReducers({
  todos: todosReducer,
  views: viewsReducer,
  responsive
});

export default rootReducer;

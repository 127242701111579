import React, { useState, useEffect } from "react";
import { useModal } from "./shared";
import { sevenIAms, commands, desktopHeader } from "../util/data";
import { Statement } from "./statements";
import { motion } from "framer-motion";
import { variants } from "../util/helpers";
import { Command } from "./commands";
import { FaithStatement } from "./shared";

export function StatementsCommands() {
  const [currentStatement, setCurrentStatement] = useState(null);
  const { faithStatement } = currentStatement || {};
  const [associatedCommands, setAssociatedCommands] = useState(null);
  const { openModal, isOpen, Modal } = useModal();
  const [currentCommand, setCurrentCommand] = useState(null);
  const [allCommands, setAllCommands] = useState(commands);

  useEffect(() => {
    if (currentStatement) {
      setAssociatedCommands(
        allCommands.filter(command => command.iAm === currentStatement.iAm)
      );
    }
  }, [currentStatement, allCommands]);

  const mappedCommands = allCommands.map((command, index) => (
    <Command
      animation={variants.item}
      setCurrentCommand={setCurrentCommand}
      clickFn={e => {
        setCurrentCommand(command);
        setAllCommands(
          allCommands.map(item => {
            if (item.number === command.number) {
              return {
                ...item,
                selected: true
              };
            }
            return {
              ...item,
              selected: false
            };
          })
        );
        openModal(e);
      }}
      key={index}
      command={command}
      type="fortynine-commands"
    />
  ));

  return (
    <div
      className={`i-am-all-statements-commands ${
        currentStatement ? "current-statement" : ""
      }`}
    >
      {!currentStatement && (
        <div className="i-am-all-statements-commands__blurb">
          <p>{desktopHeader.line1}</p>
          <p>{desktopHeader.line2}</p>
        </div>
      )}
      <div className="i-am-all-statements-commands__statements">
        <div className="i-am-all-statements-commands__label">7 STATEMENTS</div>
        <div className="i-am-all-statements-commands__statement-container">
          {sevenIAms.map((item, index) => {
            const clickFn = () => setCurrentStatement(item);
            return (
              <Statement
                clickFn={clickFn}
                buttonType={"transparent"}
                statement={item}
                key={index}
              />
            );
          })}
        </div>
      </div>
      {currentStatement ? (
        <div className={"i-am-all-statements-commands__associated-commands"}>
          <div className={"i-am-statements__header"}>
            <Statement
              clickFn={() => setCurrentStatement(null)}
              statement={currentStatement}
              buttonType={"full-border"}
              text="I AM..."
            />
          </div>
          <div className="section">
            <motion.div
              variants={variants.container}
              initial="hidden"
              animate="visible"
              className="section__list"
            >
              {associatedCommands &&
                associatedCommands.length > 0 &&
                associatedCommands.map((command, index) => {
                  return (
                    <Command
                      selected={command.selected}
                      clickFn={e => {
                        setCurrentCommand(command);
                        setAllCommands(
                          allCommands.map(item => {
                            if (item.number === command.number) {
                              return {
                                ...item,
                                selected: true
                              };
                            }
                            return {
                              ...item,
                              selected: false
                            };
                          })
                        );
                        openModal(e);
                      }}
                      animation={variants.item}
                      key={index}
                      command={command}
                      type="seven-statements"
                    />
                  );
                })}
            </motion.div>
          </div>
          {faithStatement && (
            <FaithStatement
              animation={variants.item}
              statement={faithStatement}
            />
          )}
        </div>
      ) : (
        <div className="i-am-all-statements-commands__commands">
          <div className="i-am-all-statements-commands__label">49 COMMANDS</div>
          <div className="i-am-all-statements-commands__list">
            {mappedCommands}
          </div>
        </div>
      )}
      {isOpen && (
        <Modal>
          <div className="i-am-modal__container commands">
            <div className="i-am-modal__passage">{currentCommand.passage}</div>
            <div className="i-am-modal__passage-reference">
              {currentCommand.passageReference}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export const commands = [
  {
    number: 1,
    title: "Repent",
    passage:
      '"In the same way, let your light shine before others, that they may see your good deeds and glorify your Father in heaven."',
    passageReference: "Matthew 5:16",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  },
  {
    number: 2,
    title: "Follow Me",
    passage:
      "\"'Come, follow me,' Jesus said, 'and I will send you out to fish for people.'\"",
    passageReference: "Matthew 4:19",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  },
  {
    number: 3,
    title: "Rejoice",
    passage:
      '"Blessed are you when people insult you, persecute you and falsely say all kinds of evil against you because of me. Rejoice and be glad, because great is your reward in heaven, for in the same way they persecuted the prophets who were before you."',
    passageReference: "Matthew 5:11-12",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 4,
    title: "Let Your Light Shine",
    passage:
      '"In the same way, let your light shine before others, that they may see your good deeds and glorify your Father in heaven."',
    passageReference: "Matthew 5:16",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 5,
    title: "Honor God's Law",
    passage:
      '"Do not think that I have come to abolish the Law or the Prophets; I have not come to abolish them but to fulfill them."',
    passageReference: "Matthew 5:17",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  },
  {
    number: 6,
    title: "Be Reconciled",
    passage:
      '"Therefore, if you are offering your gift at the altar and there remember that your brother or sister has something against you, leave your gift there in front of the altar. First go and be reconciled to them; then come and offer your gift. Settle matters quickly with your adversary who is taking you to court. Do it while you are still together on the way, or your adversary may hand you over to the judge, and the judge may hand you over to the officer, and you may be thrown into prison."',
    passageReference: "Matthew 5:23-25",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 7,
    title: "Do Not Lust",
    passage:
      '"But I tell you that anyone who looks at a woman lustfully has already committed adultery with her in his heart. If your right eye causes you to stumble, gouge it out and throw it away. It is better for you to lose one part of your body than for your whole body to be thrown into hell. And if your right hand causes you to stumble, cut it off and throw it away. It is better for you to lose one part of your body than for your whole body to go into hell."',
    passageReference: "Matthew 5:28-30",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 8,
    title: "Keep Your Word",
    passage:
      "\"All you need to say is simply 'Yes' or 'No'; anything beyond this comes from the evil one.\"",
    passageReference: "Matthew 5:37",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 9,
    title: "Go the Second Mile",
    passage:
      "\"You have heard that it was said, 'Eye for eye, and tooth for tooth.' But I tell you, do not resist an evil person. If anyone slaps you on the right cheek, turn to them the other cheek also. And if anyone wants to sue you and take your shirt, hand over your coat as well. If anyone forces you to go one mile, go with them two miles. Give to the one who asks you, and do not turn away from the one who wants to borrow from you.\"",
    passageReference: "Matthew 5:38-42",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 10,
    title: "Love Your Enemies",
    passage:
      '"But I tell you, love your enemies and pray for those who persecute you, that you may be children of your Father in heaven. He causes his sun to rise on the evil and the good, and sends rain on the righteous and the unrighteous. If you love those who love you, what reward will you get? Are not even the tax collectors doing that?"',
    passageReference: "Matthew 5:44-46",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 11,
    title: "Be Perfect",
    passage:
      '"If you love those who love you, what reward will you get? Are not even the tax collectors doing that? And if you greet only your own people, what are you doing more than others? Do not even pagans do that? Be perfect, therefore, as your heavenly Father is perfect."',
    passageReference: "Matthew 5:46-48",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 12,
    title: "Practice Secret Disciplines",
    passage:
      '"So when you give to the needy, do not announce it with trumpets, as the hypocrites do in the synagogues and on the streets, to be honored by others. Truly I tell you, they have received their reward in full. But when you give to the needy, do not let your left hand know what your right hand is doing, so that your giving may be in secret. Then your Father, who sees what is done in secret, will reward you. \'And when you pray, do not be like the hypocrites, for they love to pray standing in the synagogues and on the street corners to be seen by others. Truly I tell you, they have received their reward in full. But when you pray, go into your room, close the door and pray to your Father, who is unseen. Then your Father, who sees what is done in secret, will reward you. When you fast, do not look somber as the hypocrites do, for they disfigure their faces to show others they are fasting. Truly I tell you, they have received their reward in full. But when you fast, put oil on your head and wash your face, so that it will not be obvious to others that you are fasting, but only to your Father, who is unseen; and your Father, who sees what is done in secret, will reward you."',
    passageReference: "Matthew 6:1-18",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 13,
    title: "Lay Up Treasures",
    passage:
      '"Do not store up for yourselves treasures on earth, where moths and vermin destroy, and where thieves break in and steal. But store up for yourselves treasures in heaven, where moths and vermin do not destroy, and where thieves do not break in and steal. For where your treasure is, there your heart will be also."',
    passageReference: "Matthew 6:19-21",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 14,
    title: "Seek God's Kingdom",
    passage:
      '"But seek first his kingdom and his righteousness, and all these things will be given to you as well."',
    passageReference: "Matthew 6:33",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 15,
    title: "Judge Not",
    passage:
      '"Do not judge, or you too will be judged. For in the same way you judge others, you will be judged, and with the measure you use, it will be measured to you. Why do you look at the speck of sawdust in your brother\'s eye and pay no attention to the plank in your own eye?"',
    passageReference: "Matthew 7:1-3",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  },
  {
    number: 16,
    title: "Do Not Cast Pearls",
    passage:
      '"Do not give dogs what is sacred; do not throw your pearls to pigs. If you do, they may trample them under their feet, and turn and tear you to pieces."',
    passageReference: "Matthew 7:6",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 17,
    title: "Ask, Seek, Knock",
    passage:
      '"Ask and it will be given to you; seek and you will find; knock and the door will be opened to you. For everyone who asks receives; the one who seeks finds; and to the one who knocks, the door will be opened."',
    passageReference: "Matthew 7:7-8",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 18,
    title: "Do Unto Others",
    passage:
      '"So in everything, do to others what you would have them do to you, for this sums up the Law and the Prophets."',
    passageReference: "Matthew 7:12",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 19,
    title: "Choose the Narrow Way",
    passage:
      '"Enter through the narrow gate. For wide is the gate and broad is the road that leads to destruction, and many enter through it. But small is the gate and narrow the road that leads to life, and only a few find it."',
    passageReference: "Matthew 7:13-14",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  },
  {
    number: 20,
    title: "Beware of False Prophets",
    passage:
      '"Watch out for false prophets. They come to you in sheep\'s clothing, but inwardly they are ferocious wolves. By their fruit you will recognize them. Do people pick grapes from thornbushes, or figs from thistles?"',
    passageReference: "Matthew 7:15-16",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 21,
    title: "Pray for Laborers",
    passage:
      "\"Then he said to his disciples, 'The harvest is plentiful but the workers are few. Ask the Lord of the harvest, therefore, to send out workers into his harvest field.'\"",
    passageReference: "Matthew 9:37-38",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 22,
    title: "Be Wise as Serpents",
    passage:
      '"I am sending you out like sheep among wolves. Therefore be as shrewd as snakes and as innocent as doves."',
    passageReference: "Matthew 10:16",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 23,
    title: "Fear Not",
    passage:
      '"Do not be afraid of those who kill the body but cannot kill the soul. Rather, be afraid of the One who can destroy both soul and body in hell."',
    passageReference: "Matthew 10:28",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 24,
    title: "Hear God's Voice",
    passage: '"Whoever has ears, let them hear."',
    passageReference: "Matthew 11:15",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 25,
    title: "Take My Yoke",
    passage:
      '"Come to me, all you who are weary and burdened, and I will give you rest. Take my yoke upon you and learn from me, for I am gentle and humble in heart, and you will find rest for your souls. For my yoke is easy and my burden is light."',
    passageReference: "Matthew 11:28-30",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  },
  {
    number: 26,
    title: "Honor Your Parents",
    passage:
      "\"For God said, 'Honor your father and mother' and 'Anyone who curses their father or mother is to be put to death.'\"",
    passageReference: "Matthew 15:4",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 27,
    title: "Beware of Leaven",
    passage:
      "\"'Be careful,' Jesus said to them. 'Be on your guard against the yeast of the Pharisees and Sadducees.'\"",
    passageReference: "Matthew 16:6",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 28,
    title: "Deny Yourself",
    passage:
      "\"Then he said to them all: 'Whoever wants to be my disciple must deny themselves and take up their cross daily and follow me. For whoever wants to save their life will lose it, but whoever loses their life for me will save it. What good is it for someone to gain the whole world, and yet lose or forfeit their very self?'\"",
    passageReference: "Luke 9:23-25",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 29,
    title: "Despise Not Little Ones",
    passage:
      '"See that you do not despise one of these little ones. For I tell you that their angels in heaven always see the face of my Father in heaven."',
    passageReference: "Matthew 18:10",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 30,
    title: "Go to Offenders",
    passage:
      "\"If your brother or sister sins, go and point out their fault, just between the two of you. If they listen to you, you have won them over. But if they will not listen, take one or two others along, so that 'every matter may be established by the testimony of two or three witnesses.' If they still refuse to listen, tell it to the church; and if they refuse to listen even to the church, treat them as you would a pagan or a tax collector.\"",
    passageReference: "",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 31,
    title: "Beware of Covetous-\nness",
    passage:
      "\"Then he said to them, 'Watch out! Be on your guard against all kinds of greed; life does not consist in an abundance of possessions.'\"",
    passageReference: "Luke 12:15",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 32,
    title: "Forgive Offenders",
    passage:
      "\"Then Peter came to Jesus and asked, 'Lord, how many times shall I forgive my brother or sister who sins against me? Up to seven times?' Jesus answered, 'I tell you, not seven times, but seventy-seven times.'\"",
    passageReference: "Matthew 18:21-22",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 33,
    title: "Honor Marriage",
    passage:
      "\"'Haven't you read,' he replied, 'that at the beginning the Creator `made them male and female,` and said, `For this reason a man will leave his father and mother and be united to his wife, and the two will become one flesh`? So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate.'\"",
    passageReference: "Matthew 19:4-6",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 34,
    title: "Be a Servant",
    passage:
      '"...Instead, whoever wants to become great among you must be your servant, and whoever wants to be first must be your slave—just as the Son of Man did not come to be served, but to serve, and to give his life as a ransom for many."',
    passageReference: "Matthew 20:26-28",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 35,
    title: "Be a House of Prayer",
    passage:
      "\"'It is written,' he said to them, 'My house will be called a house of prayer'...\"",
    passageReference: "Matthew 21:13",
    iAm: "The Light of the World",
    selected: false
  },
  {
    number: 36,
    title: "Ask in Faith",
    passage:
      "\"Truly I tell you, if you have faith and do not doubt, not only can you do what was done to the fig tree, but also you can say to this mountain, 'Go, throw yourself into the sea,' and it will be done. If you believe, you will receive whatever you ask for in prayer.\"",
    passageReference: "Matthew 21:21-22",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 37,
    title: "Bring In the Poor",
    passage:
      "\"Then Jesus said to his host, 'When you give a luncheon or dinner, do not invite your friends, your brothers or sisters, your relatives, or your rich neighbors; if you do, they may invite you back and so you will be repaid. But when you give a banquet, invite the poor, the crippled, the lame, the blind, and you will be blessed. Although they cannot repay you, you will be repaid at the resurrection of the righteous.'\"",
    passageReference: "Luke 14:12-14",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 38,
    title: "Render to Caesar",
    passage:
      "\"'Show me the coin used for paying the tax.' They brought him a denarius, and he asked them, 'Whose image is this? And whose inscription?' 'Caesar's,' they replied. Then he said to them, 'So give back to Caesar what is Caesar's, and to God what is God's.'\"",
    passageReference: "Matthew 22:19-21",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 39,
    title: "Love the Lord",
    passage:
      "\"Jesus replied: 'Love the Lord your God with all your heart and with all your soul and with all your mind.' This is the first and greatest commandment.'\"",
    passageReference: "Matthew 22:37-38",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 40,
    title: "Love Your Neighbor",
    passage:
      "\"And the second is like it: 'Love your neighbor as yourself.' All the Law and the Prophets hang on these two commandments.\"",
    passageReference: "Matthew 22:39-40",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 41,
    title: "Await My Return",
    passage:
      '"Therefore keep watch, because you do not know on what day your Lord will come. But understand this: If the owner of the house had known at what time of night the thief was coming, he would have kept watch and would not have let his house be broken into. So you also must be ready, because the Son of Man will come at an hour when you do not expect him."',
    passageReference: "Matthew 24:42-44",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 42,
    title: "Take, Eat, and Drink",
    passage:
      "\"While they were eating, Jesus took bread, and when he had given thanks, he broke it and gave it to his disciples, saying, 'Take and eat; this is my body.' Then he took a cup, and when he had given thanks, he gave it to them, saying, 'Drink from it, all of you. This is my blood of the covenant, which is poured out for many for the forgiveness of sins.'\"",
    passageReference: "Matthew 26:26-28",
    iAm: "The Bread of Life",
    selected: false
  },
  {
    number: 43,
    title: "Be Born Again",
    passage:
      "\"Jesus answered, 'Very truly I tell you, no one can enter the kingdom of God unless they are born of water and the Spirit. Flesh gives birth to flesh, but the Spirit gives birth to spirit. You should not be surprised at my saying, `You must be born again.`'\"",
    passageReference: "John 3:5-7",
    iAm: "The Resurrection",
    selected: false
  },
  {
    number: 44,
    title: "Love the Way I Loved",
    passage:
      "\"If you love me, keep my commands...My command is this: Love each other as I have loved you. Greater love has no one than this: to lay down one's life for one's friends. This is my command: Love each other.\"",
    passageReference: "John 14:15; 15:12-13, 17",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 45,
    title: "Watch and Pray",
    passage:
      '"Watch and pray so that you will not fall into temptation. The spirit is willing, but the flesh is weak."',
    passageReference: "Matthew 26:41",
    iAm: "The Gate",
    selected: false
  },
  {
    number: 46,
    title: "Feed My Sheep",
    passage:
      "\"When they had finished eating, Jesus said to Simon Peter, 'Simon son of John, do you love me more than these?' 'Yes, Lord,' he said, 'you know that I love you.' Jesus said, 'Feed my lambs.' Again Jesus said, 'Simon son of John, do you love me?' He answered, 'Yes, Lord, you know that I love you.' Jesus said, 'Take care of my sheep.'\"",
    passageReference: "John 21:15-16",
    iAm: "The Good Shepherd",
    selected: false
  },
  {
    number: 47,
    title: "Baptize My Disciples",
    passage:
      '"Therefore go and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit..."',
    passageReference: "Matthew 28:19",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 48,
    title: "Receive God's Power",
    passage:
      '"I am going to send you what my Father has promised; but stay in the city until you have been clothed with power from on high."',
    passageReference: "Luke 24:49",
    iAm: "The Vine",
    selected: false
  },
  {
    number: 49,
    title: "Make Disciples",
    passage:
      '"Therefore go and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit, and teaching them to obey everything I have commanded you. And surely I am with you always, to the very end of the age."',
    passageReference: "Matthew 28:19-20",
    iAm: "The Way, the Truth, and the Life",
    selected: false
  }
];

export const sevenIAms = [
  {
    number: 1,
    iAm: "The Light of the World",
    statementTitle: "The Light",
    passage:
      'Jesus spoke to them again: "I am the light of the world. Anyone who follows me will never walk in the darkness but will have the light of life."',
    passageReference: "John 8:12",
    faithStatement:
      "EKKO believes Jesus is the Light of the World. Jesus is the light that guides us out of evil and illuminates the path of righteousness. He shines through our lives for the world to find their way home to God."
  },
  {
    number: 2,
    iAm: "The Gate",
    statementTitle: "The Gate",
    passage:
      'Jesus said again, "Truly I tell you, I am the gate for the sheep."',
    passageReference: "John 10:7",
    faithStatement:
      "EKKO believes Jesus is the Gate and the Good Shepherd. Through Him, we enter salvation; by Him, we are protected; in Him, we have peace. Because He laid down His life for us, we can relinquish the temptation to worry or self-preserve, and instead lay down our lives for others."
  },
  {
    number: 3,
    iAm: "The Way, the Truth, and the Life",
    statementTitle: "The Way, Truth, Life",
    passage:
      'Jesus told him, "I am the way, the truth, and the life. No one comes to the Father except through me."',
    passageReference: "John 14:6",
    faithStatement:
      "EKKO believes Jesus is the Way, Truth and the Life, and because of this, we can follow Him no matter how narrow the path or arduous the journey. He is the Way, Truth and Life, therefore we can submit to Him and trust His direction. Where He leads, we will follow."
  },
  {
    number: 4,
    iAm: "The Vine",
    statementTitle: "The Vine",
    passage: "I am the true vine, and my Father is the gardener.",
    passageReference: "John 15:1",
    faithStatement:
      "EKKO believe Jesus is the Vine and that He invites us to a life of apprenticeship and abiding in Him. Despite our own limitations or disqualifications, we are branches grated into Him. His life flows in us so that we can produce lasting fruit in others and in the world."
  },
  {
    number: 5,
    iAm: "The Resurrection",
    statementTitle: "The Resurrection",
    passage:
      'Jesus said to her, "I am the resurrection and the life. The one who believes in me, even if he dies, will live."',
    passageReference: "John 11:25",
    faithStatement:
      "EKKO believes Jesus is the Resurrection and the Life, that’s why we can live life anticipating joy despite seemingly hopeless situations and “dead ends”. In Christ death is silenced for God alone has the final say."
  },
  {
    number: 6,
    iAm: "The Good Shepherd",
    statementTitle: "The Good Shepherd",
    passage:
      "I am the good shepherd. The good shepherd lays down his life for the sheep.",
    passageReference: "John 10:11",
    faithStatement:
      "EKKO believes Jesus is the Gate and the Good Shepherd. Through Him, we enter salvation; by Him, we are protected; in Him, we have peace. Because He laid down His life for us, we can relinquish the temptation to worry or self-preserve, and instead lay down our lives for others."
  },
  {
    number: 7,
    iAm: "The Bread of Life",
    statementTitle: "The Bread",
    passage:
      '"I am the bread of life," Jesus told them, "No one who comes to me will ever be hungry, and no one who believes in me will ever be thirsty again."',
    passageReference: "John 6:35",
    faithStatement:
      "EKKO believes Jesus is the Bread of Life. He is our portion and our sustenance; every need is completely filled and satisfied in Him. Because of Him, we can give out of the overflow to meet the needs of the hungry in our midst."
  }
];

export const desktopHeader = {
  line1:
    "The seven I AM statements found in the Gospel of John reveal to us Jesus' character and nature; and those seven statements, in turn, empower us as His disciples to practice His forty-nine commands. As we understand His nature and practice his ways, we are formed into His image.",
  line2: "Let's practice."
};

import React from "react";
import { EkkoLogo } from "./svg";
import { motion } from "framer-motion";
import usePortal from "react-useportal";
import { useDispatch, useSelector } from "react-redux";
import { selectView } from "../actions/index";

export function FloatingButton() {
  const dispatch = useDispatch();
  return (
    <div onClick={() => dispatch(selectView("HOME"))} className="fab-button">
      <EkkoLogo />
    </div>
  );
}

export const useModal = () => {
  const root = document.querySelector("#root");
  const { isOpen, togglePortal, closePortal, Portal } = usePortal({
    onOpen({ portal }) {
      portal.current.style.cssText = `
        /* add your css here for the Portal */
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
      `;
      const fabButton = document.querySelector(".fab-button");
      if (fabButton) {
        fabButton.style.transition = "all 0.2s linear";
        fabButton.style.filter = "brightness(0.2)";
        fabButton.style.pointerEvents = "none";
      }

      root.className = "modal-on";

      portal.current.className = "i-am-modal";
    },
    onClose() {
      const fabButton = document.querySelector(".fab-button");
      if (fabButton) {
        fabButton.style.filter = "brightness(1)";
        fabButton.style.pointerEvents = "initial";
      }

      root.className = "";
    }
  });

  return {
    Modal: Portal,
    openModal: togglePortal,
    closeModal: closePortal,
    isOpen
  };
};

export const IAmButton = ({ buttonType, children, clickFn, animation }) => {
  return (
    <motion.button
      variants={animation}
      initial="hidden"
      animate="visible"
      onClick={clickFn}
      className={`i-am-button i-am-button--${buttonType}`}
    >
      {children}
    </motion.button>
  );
};

export const FaithStatement = ({ animation, statement }) => {
  const { isPhone } = useSelector(state => state.responsive);
  return (
    <motion.div variants={animation} className="i-am-faith-statement">
      <div className="i-am-faith-statement__header">OUR FAITH STATEMENT</div>
      <div className="i-am-faith-statement__body">{statement}</div>
      {!isPhone && (
        <div className="i-am-faith-statement__logo">
          <EkkoLogo />
        </div>
      )}
    </motion.div>
  );
};

import React from "react";
import { IAmButton } from "./shared";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { variants } from "../util/helpers";
import { selectView } from "../actions/index";
import { IAmLogo } from "./svg";

export const HomeMobile = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="i-am__header-container">
        <IAmLogo />
        <motion.h1
          variants={variants.container}
          initial="hidden"
          animate="visible"
          className="i-am__header-verse"
        >
          "IN HIM, WE LIVE AND MOVE AND HAVE OUR BEING."
        </motion.h1>
        <motion.h2
          variants={variants.container}
          initial="hidden"
          animate="visible"
          className="i-am__header-passage"
        >
          ACTS 17:28
        </motion.h2>
      </div>
      <motion.div
        variants={variants.container}
        initial="hidden"
        animate="visible"
        className="i-am__body"
        transition={{
          default: { duration: 3, ease: "easeInOut" }
        }}
      >
        <p>
          The seven I AM statements found in the Gospel of John reveal to us
          Jesus' character and nature; and those seven statements, in turn,
          empower us as His disciples to practice His forty-nine commands. As
          we understand His nature and practice his ways, we are formed into His
          image.   
        </p>
      </motion.div>

      <div className="i-am__button-group">
        <IAmButton
          animation={variants.container}
          type={"transparent"}
          clickFn={() => dispatch(selectView("STATEMENTS"))}
          buttonType={"transparent"}
        >
          7 STATEMENTS
        </IAmButton>
        <IAmButton
          animation={variants.container}
          type={"transparent"}
          clickFn={() => dispatch(selectView("COMMANDS"))}
          buttonType={"transparent"}
        >
          49 COMMANDS
        </IAmButton>
      </div>
    </>
  );
};

export const HomeDesktop = () => {
  const dispatch = useDispatch();
  return (
    <div className="i-am__header-container">
      <div className="i-am__logo">
        <IAmLogo />
      </div>
      <motion.div
        className="i-am__body"
        variants={variants.container}
        initial="hidden"
        animate="visible"
      >
        <h1 className="i-am__header-verse">
          "IN HIM, WE LIVE AND MOVE AND HAVE OUR BEING."
        </h1>
        <h2 className="i-am__header-passage">ACTS 17:28</h2>
      </motion.div>

      <IAmButton
        animation={variants.container}
        clickFn={() => dispatch(selectView("STATEMENTS"))}
        buttonType={"solid"}
      >
        7 STATEMENTS - 49 COMMANDS
      </IAmButton>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { sevenIAms, commands } from "../util/data";
import { IAmButton, FaithStatement, useModal } from "./shared";
import { motion } from "framer-motion";
import { Command } from "./commands";
import { variants } from "../util/helpers";

export function Statements() {
  const [currentStatement, setCurrentStatement] = useState(null);
  const { faithStatement } = currentStatement || {};

  const [associatedCommands, setAssociatedCommands] = useState(null);
  const { openModal, closeModal, isOpen, Modal } = useModal();
  const [currentCommand, setCurrentCommand] = useState(null);
  const [allCommands, setAllCommands] = useState(commands);

  useEffect(() => {
    if (currentStatement) {
      setAssociatedCommands(
        allCommands.filter(command => command.iAm === currentStatement.iAm)
      );
    }
  }, [currentStatement, allCommands]);

  return (
    <motion.div
      className={`i-am-statements ${currentStatement ? "show-commands" : ""}`}
      variants={variants.container}
      initial="hidden"
      animate="visible"
    >
      {currentStatement ? (
        <>
          <div className={"i-am-statements__header"}>
            <Statement
              clickFn={() => setCurrentStatement(null)}
              statement={currentStatement}
              buttonType={"full-border"}
              text="I AM..."
            />
          </div>
          <div className="section">
            <motion.div
              variants={variants.container}
              initial="hidden"
              animate="visible"
              className="section__list"
            >
              {associatedCommands &&
                associatedCommands.length > 0 &&
                associatedCommands.map((command, index) => {
                  return (
                    <Command
                      selected={command.selected}
                      clickFn={e => {
                        setCurrentCommand(command);
                        setAllCommands(
                          allCommands.map(item => {
                            if (item.number === command.number) {
                              return {
                                ...item,
                                selected: true
                              };
                            }
                            return {
                              ...item,
                              selected: false
                            };
                          })
                        );
                        openModal(e);
                      }}
                      animation={variants.item}
                      key={index}
                      command={command}
                      type="seven-statements"
                    />
                  );
                })}
            </motion.div>
          </div>
          {faithStatement && (
            <FaithStatement
              animation={variants.item}
              statement={faithStatement}
            />
          )}
        </>
      ) : (
        <>
          <h2 className="i-am-statements__header">7 STATEMENTS</h2>
          {sevenIAms.map((item, index) => {
            const clickFn = () => setCurrentStatement(item);
            return (
              <Statement
                clickFn={clickFn}
                statement={item}
                key={index}
                buttonType={"transparent-title"}
              />
            );
          })}
        </>
      )}
      {isOpen && (
        <Modal>
          <div className="i-am-modal__container commands">
            <div className="i-am-modal__passage">{currentCommand.passage}</div>
            <div className="i-am-modal__passage-reference">
              {currentCommand.passageReference}
            </div>
          </div>
        </Modal>
      )}
    </motion.div>
  );
}

export function Statement({ statement, clickFn, buttonType, text }) {
  return (
    <IAmButton
      clickFn={clickFn}
      animation={variants.item}
      buttonType={buttonType}
    >
      {statement &&
        `${text ? text : ""}${statement.statementTitle.toUpperCase()}`}
    </IAmButton>
  );
}

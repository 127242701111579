import HOME from "../images/iam-desktop.jpeg";
import STATEMENTS from "../images/i-am-2.jpeg";
import COMMANDS from "../images/i-am-3.jpeg";

export function fixViews(views, isPhone) {
  let fixedView = views;
  if (!isPhone && views === "COMMANDS") {
    fixedView = "STATEMENTS";
  }
  return typeof fixedView === "string" ? fixedView.toLowerCase() : "home";
}

export function getImage(views) {
  return {
    HOME: HOME,
    STATEMENTS: STATEMENTS,
    COMMANDS: COMMANDS
  }[views];
}

export const variants = {
  logo: {
    hidden: {
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
      stroke: "rgba(255, 255, 255, 0)"
    },
    visible: {
      pathLength: 1,
      fill: "#0ff",
      stroke: "#0ff"
    }
  },
  container: {
    hidden: { opacity: 0, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.3,
        duration: 2,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  },
  item: {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.3 }
    }
  }
};

import React from "react";
import ReactDOM from "react-dom";
import { mediaQueryTracker } from "redux-mediaquery";
import "normalize.css";
import "./styles/styles.scss";
import store from "./store";
import { Provider } from "react-redux";

function render() {
  const unlisten = mediaQueryTracker(
    {
      isPhone: "screen and (max-width: 767px)",
      isTablet: "screen and (max-width: 1024px)",
      innerWidth: true,
      innerHeight: true
    },
    store.dispatch
  );

  const IAm = require("./IAm").default;

  ReactDOM.render(
    <Provider store={store}>
      <IAm />
    </Provider>,
    document.getElementById("root")
  );
}

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./IAm", render);
}

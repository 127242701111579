import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { HomeMobile, HomeDesktop } from "./components/homeScreen";
import { CommandsList } from "./components/commands";
import { Statements } from "./components/statements";
import { StatementsCommands } from "./components/statementsCommands";
import { useSelector } from "react-redux";
import HOME from "./images/iam-desktop.jpeg";
import STATEMENTS from "./images/i-am-2.jpeg";
import COMMANDS from "./images/i-am-3.jpeg";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { fixViews } from "./util/helpers";
import { FloatingButton } from "./components/shared";

const IAm = () => {
  const { isPhone } = useSelector(state => state.responsive);
  const views = useSelector(state => state.views);
  const [fixedView, setFixedView] = useState(views);

  useEffect(() => {
    setFixedView(fixViews(views, isPhone));
  }, [views, isPhone]);

  return (
    <>
      <main
        style={{ backgroundImage: "url(" + STATEMENTS + ")" }}
        className={`i-am i-am--${
          isPhone ? "mobile" : "desktop"
        } i-am--${fixedView}`}
      >
        {/* {isPhone && <BackgroundImages views={views} />} */}
        {isPhone
          ? {
              HOME: <HomeMobile />,
              STATEMENTS: <Statements />,
              COMMANDS: <CommandsList />
            }[views]
          : {
              HOME: <HomeDesktop />,
              STATEMENTS: <StatementsCommands />,
              COMMANDS: <StatementsCommands />
            }[views]}
      </main>
      {/* {!isPhone && <BackgroundImages views={views} />} */}
      {views !== "HOME" &&
        createPortal(<FloatingButton />, document.querySelector("body"))}
    </>
  );
};

export default IAm;

// function BackgroundImages() {
//   const views = useSelector(state => state.views);
//   const [currentImage, setCurrentImage] = useState(HOME);

//   function getImage(views) {
//     return {
//       HOME: HOME,
//       STATEMENTS: STATEMENTS,
//       COMMANDS: COMMANDS,
//       ALL: STATEMENTS
//     }[views];
//   }

//   useEffect(() => {
//     setCurrentImage(getImage(views));
//   }, [views]);

//   return (
//     <div className="background-image">
//       <TransitionGroup className="background-image__container">
//         <CSSTransition key={currentImage} timeout={1000} classNames="picture">
//           <img alt={"i am"} className={`img-responsive`} src={currentImage} />
//         </CSSTransition>
//       </TransitionGroup>
//     </div>
//   );
// }
